import NeighboringUse from "@/models/NeighboringUse";
import socioGrpcClient from "@/setup/socioGrpcClient";
import { sspMetadata } from "@/utils/metadata";
import store from "@/store";
const state = {
  neighboringUsesCount: 0,
};

const mutations = {
  SET_NEIGHBORING_USES_COUNT: (state, count) => {
    state.neighboringUsesCount = count;
  },
};

const getters = {
  neiboringUsesList: (state, getters, rootState) => {
    return NeighboringUse.query()
      .where("siteUuid", rootState.site.currentSite?.uuid)
      .get();
  },
  getNeighboringUsesCount: (state) => {
    return state.neighboringUsesCount;
  },
};

const actions = {
  async fetchNeighboringUse({ commit }, metadata = {}) {
    const site = store.getters["site/currentSite"];
    const request = new socioGrpcClient.ssp_back.projects.NeighborUseRequest();
    request.setSite(site.uuid);
    const response =
      await socioGrpcClient.ssp_back.projects.NeighborUseControllerPromiseClient.list(
        request,
        sspMetadata(metadata)
      );
    if (response) {
      const { resultsList, count } = response.toObject();
      commit("SET_NEIGHBORING_USES_COUNT", count);
      await NeighboringUse.create({
        data: resultsList,
      });
    }
  },
  async createOrUpdateCurrentNeighboringUse(_, neighboringUse) {
    let response = null;
    const site = store.getters["site/currentSite"];
    neighboringUse.site = site.uuid;
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.projects.NeighborUseRequest,
      neighboringUse
    );
    if (!neighboringUse.uuid || neighboringUse.uuid.includes("$")) {
      response =
        await socioGrpcClient.ssp_back.projects.NeighborUseControllerPromiseClient.create(
          request,
          sspMetadata()
        );
      if (response) {
        return await NeighboringUse.insert({
          data: response.toObject(),
        });
      }
    }
    response =
      await socioGrpcClient.ssp_back.projects.NeighborUseControllerPromiseClient.update(
        request,
        sspMetadata()
      );
    if (response) {
      return await NeighboringUse.insert({
        data: response.toObject(),
      });
    }
  },
  async deleteCurrentNeighboringUse(_, uuid) {
    const request = new socioGrpcClient.ssp_back.projects.NeighborUseRequest();
    request.setUuid(uuid);
    await socioGrpcClient.ssp_back.projects.NeighborUseControllerPromiseClient.destroy(
      request,
      sspMetadata()
    );
    return NeighboringUse.delete(uuid);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
