import socioGrpcClient from "@/setup/socioGrpcClient";
import { buildClient } from "./utils";

const cartoModule = socioGrpcClient.ssp_back.cartography;

export const buildingClient = buildClient(
  cartoModule,
  cartoModule.BuildingControllerPromiseClient,
  {
    retrieveRequest: cartoModule.BuildingRetrieveRequest,
    createRequest: cartoModule.BuildingRequest,
    updateRequest: cartoModule.BuildingUpdateRequest,
    destroyRequest: cartoModule.BuildingDestroyRequest,
    listRequest: cartoModule.BuildingListRequest,
    partialUpdateRequest: cartoModule.BuildingPartialUpdateRequest,
  }
);

export const polygonClient = buildClient(
  cartoModule,
  cartoModule.PolygonControllerPromiseClient,
  {
    retrieveRequest: cartoModule.PolygonRetrieveRequest,
    createRequest: cartoModule.PolygonRequest,
    updateRequest: cartoModule.PolygonUpdateRequest,
    destroyRequest: cartoModule.PolygonDestroyRequest,
    listRequest: cartoModule.PolygonListRequest,
    partialUpdateRequest: cartoModule.PolygonPartialUpdateRequest,
  }
);

export const siteClient = buildClient(
  cartoModule,
  cartoModule.SiteControllerPromiseClient,
  {
    retrieveRequest: cartoModule.SiteRetrieveRequest,
    createRequest: cartoModule.SiteRequest,
    updateRequest: cartoModule.SiteUpdateRequest,
    destroyRequest: cartoModule.SiteDestroyRequest,
    listRequest: cartoModule.SiteListRequest,
    partialUpdateRequest: cartoModule.SitePartialUpdateRequest,
  }
);
export const zoneClient = buildClient(
  cartoModule,
  cartoModule.ZoneControllerPromiseClient,
  {
    retrieveRequest: cartoModule.ZoneRetrieveRequest,
    createRequest: cartoModule.ZoneRequest,
    updateRequest: cartoModule.ZoneUpdateRequest,
    destroyRequest: cartoModule.ZoneDestroyRequest,
    listRequest: cartoModule.ZoneListRequest,
    partialUpdateRequest: cartoModule.ZonePartialUpdateRequest,
  }
);

export const otherItemClient = buildClient(
  cartoModule,
  cartoModule.OtherItemControllerPromiseClient,
  {
    retrieveRequest: cartoModule.OtherItemRetrieveRequest,
    createRequest: cartoModule.OtherItemRequest,
    updateRequest: cartoModule.OtherItemUpdateRequest,
    destroyRequest: cartoModule.OtherItemDestroyRequest,
    listRequest: cartoModule.OtherItemListRequest,
    partialUpdateRequest: cartoModule.OtherItemPartialUpdateRequest,
  }
);

export const componentClient = buildClient(
  cartoModule,
  cartoModule.ComponentControllerPromiseClient,
  {
    retrieveRequest: cartoModule.ComponentRetrieveRequest,
    createRequest: cartoModule.ComponentRequest,
    updateRequest: cartoModule.ComponentUpdateRequest,
    destroyRequest: cartoModule.ComponentDestroyRequest,
    listRequest: cartoModule.ComponentListRequest,
    partialUpdateRequest: cartoModule.ComponentPartialUpdateRequest,
  }
);

export const outdoorSpaceClient = buildClient(
  cartoModule,
  cartoModule.OutdoorSpaceControllerPromiseClient,
  {
    retrieveRequest: cartoModule.OutdoorSpaceRetrieveRequest,
    createRequest: cartoModule.OutdoorSpaceRequest,
    updateRequest: cartoModule.OutdoorSpaceUpdateRequest,
    destroyRequest: cartoModule.OutdoorSpaceDestroyRequest,
    listRequest: cartoModule.OutdoorSpaceListRequest,
    partialUpdateRequest: cartoModule.OutdoorSpacePartialUpdateRequest,
  }
);
